body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*******PropertyComp*********/

.propertyComp__description-containe{
  font-weight: 300;
  font-family: Lato;
  height: 13rem;
  line-height: 30px;
  margin-bottom: 2rem;
  overflow: hidden;
}
/*******PropertyComp*********/

.cardProperty__wrapper-btn{
  position: absolute;
  bottom: 3rem;
}