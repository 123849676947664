/* Remove all shit outline on focus */
:focus {
  outline: 0 !important;
}
.sliderNavContainer {
  display: flex;
  max-width: 1200px;
  flex-flow: column;
  align-content: center;
  justify-items: center;
  justify-content: space-evenly;
}

.sliderNavContainer > div {
  width: 100%;
  margin-bottom: 40px;
  max-width: 900px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(145, 145, 145, 0.3);
}

.imageNavSlider {
  /*height: 200px;*/
  min-height: 200px;
  max-width: 400px;
  max-height: 200px;
}
.animatedmenu {
  animation: moveInBottom 0.5s ease-out 0.1s;
  animation-fill-mode: backwards;
}
.menum {
  display: none !important;
}

.shadow {
  text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
}

.property-hover:hover {
  background-color: rgba(23, 81, 111, 0.5);
}
.property-hover:hover .button-property {
  display: inline-block !important;
}

.button-property {
  background: #5aa7d1 !important;
  color: #ffffff !important;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  display: none;
}

.headerImage {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  /*height: 56.4rem;*/
  height: 29.5rem;
  z-index: -1;
  position: absolute;
  background-position: center;
  background-size: cover;
}

@media screen and (min-width: 600px) {
  /*
  .headerImage {
    width: 100%;
    height: 31rem;
    z-index: -1;
    position: absolute;
    background-size: cover;
    background-color: #868686;
    background-position: center;
    background-repeat: no-repeat;
  }*/
}

.flexContainer {
  display: flex;
  flex-direction: row;
}
.flexContainer .box {
  margin-right: 50px;
}

/* Slider Buttons */

.Sbutton {
  display: none;
}

.SButton2 {
  margin: 0 auto;
  width: 155px;
  height: 50px;
  border-radius: 30px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: space-evenly;
  justify-items: center;
  align-content: stretch;
}

/* Buttons */

.DButton,
.DButtonInv {
  width: 248px !important;
  height: 50px !important;
  border-radius: 30px !important;
  color: #5aa7d1 !important;
  border: 1px solid #5aa7d1 !important;
  background: none !important;
  cursor: pointer !important;
  outline: none !important;
  display: flex !important;
  align-items: center !important;
  align-self: center !important;
  justify-content: center !important;
}
.DButtonInv {
  color: white !important;
  background-color: #5aa7d1 !important;
}
.DButton:hover {
  background-color: #5aa7d1 !important;
  color: white !important;
}

.Sbutton > img,
.SButton2 > div {
  margin-top: 9px;
}

.logoContainer {
  margin: 0 auto;
  background: white;
  display: flex;
  justify-content: center;
  width: 180px;
  height: 9rem;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 4px 4px;
}
.headingSplash {
  font-size: 2rem !important;
  padding: 0;
  text-align: center !important;
  font-size: 5rem;
  color: white;
  font-weight: bold;
}
.headingSub {
  font-size: 1rem !important;
  text-align: center;
}
.mainMenu {
  display: none !important;
}
.mainMenuItem:hover {
  background-color: #5aa7d1 !important;
  color: white !important;
}

.splashBg {
  background-attachment: fixed !important;
  background-color: #017387 !important;
  background-size: cover !important;
  background-position: center center;
  height: 100vh !important;
  width: 100vw !important;
  z-index: 9;
  min-height: 100vh !important;
}

.mobileNav {
  top: 69vh;
  position: absolute !important;
  width: 95% !important;
  text-align: center;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.ui.menu .item:before {
  height: 70% !important;
  top: 16% !important;
}

.ui.menu.three.item .item:hover {
  background-color: #5aa7d1;
  border-radius: 4px 4px 0px 0px;
}
.title {
  font-size: 1.7rem !important;
}
.headingContainer {
  top: 20%;
  position: relative;
}

.menum {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  padding-top: 2rem;
  font-size: 40px;
  font-weight: bold;
  background-color: white;
  width: 100vw;
  height: 42rem;
  z-index: 9;
  color: black !important;
}

.menumIntern {
  top: 29rem;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  padding-top: 2rem;
  font-size: 40px;
  font-weight: bold;
  background-color: white;
  width: 100vw;
  height: 24rem;
  z-index: 9;
  color: black !important;
}
.title {
  font-size: 1.5rem;
}
.fixMarginBottomByH {
  height: 40px;
}
.marginTopByH {
  height: 40px;
}
.marginTop {
  margin-top: 40px;
}
.marginBottom {
  margin-bottom: 20px;
}
.fixSpaceContact {
  padding-top: 40px;
}
.fixMarginBottom {
  margin-bottom: 40px;
}
.autoAlign {
  text-align: left;
}
.amenitiesSlider {
  width: 100%;
  height: 200px;
}
.titleShadow {
  text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
}
/*****************************
* MEDIA QUERY CSS
******************************/

@media only screen and (min-width: 600px) {
  .sliderNavContainer > div {
    border-bottom: none;
  }
  .headerImage {
    height: 29.5rem;
  }
  .logoContainer {
    margin: 0 auto;
    width: 210px;
    height: 10rem;
  }
  .DButton {
    width: 248px;
    height: 50px;
    border-radius: 30px;
  }
  .SButtonNav {
    margin-top: 8px;
  }
  .Sbutton {
    width: 143px;
    height: 50px;
    border-radius: 30px;
    background-color: #ffffff;
    border: 1px solid #5aa7d1;
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: space-evenly;
    justify-items: center;
    align-content: stretch;
  }
}

@media only screen and (min-width: 768px) {
  .imageNavSlider {
    max-width: 300px;
  }
  .sliderNavContainer {
    flex-flow: row;
  }
  .sliderNavContainer > div {
    width: 400px;
  }

  .amenitiesSlider {
    height: 150px;
    width: 210px;
  }
  .autoAlign {
    text-align: center;
  }
  .fixMarginBottom {
    margin-bottom: 80px;
  }
  .fixMarginBottomByH {
    height: 80px;
  }
  .marginTopByH {
    height: 80px;
  }
  .fixSpaceContact {
    padding-top: 80px;
  }
  .marginTop {
    margin-top: 80px;
  }
  .marginBottom {
    margin-bottom: 30px;
  }
  .sliderArrow {
    margin-top: 8px;
  }
  .headerImage {
    height: 37.2rem;
  }
  .title {
    font-size: 2.5rem !important;
  }
  .headingSplash {
    font-size: 5rem !important;
    margin: 5rem 0 2rem 0;
    text-align: center !important;
  }
  .headingSub {
    font-size: 2.7rem !important;
    font-weight: normal;
    text-align: center;
  }
}
@media only screen and (min-width: 992px) {
  .headerImage {
    height: 56.2rem;
  }
}
@media only screen and (min-width: 1024px) {
  /*
  .imageNavSlider{
    height: 250px;
  }*/
  .amenitiesSlider {
    height: 250px;
    width: 90%;
  }
  .fixMarginBottom {
    margin-bottom: 120px;
  }
  .fixSpaceContact {
    padding-top: 120px;
  }
  .marginTop {
    margin-top: 120px;
  }
  .fixMarginBottomByH {
    height: 120px;
  }
  .marginTopByH {
    height: 120px;
  }
  .marginBottom {
    margin-bottom: 40px;
  }
  .headerImage {
    height: 100vh;
  }
  .mobileNav {
    display: none !important;
  }
  .mainMenuContainer {
    overflow: hidden;
    color: black !important;
  }
  .mainMenu {
    top: 80vh;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, 0%);
    display: flex !important;
    position: absolute;
    height: 4rem !important;
    width: 60rem !important;
    text-align: center;
  }
}
